import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'en',
  messages: {
    'en': {
      worng_link:"Sorry this link is incorrect",
      field_is_required:"This field is required",
      field_is_duplicated:"is duplicated",
      max_char:"Maximum allowed characters is ",
      min_char:"Minimum allowed characters is ",
      should_greater_than:"should be greater than ",
      should_less_than:"should be less than ",
      valid_email:"Please enter a valid email",
      submit:"Submit",
      file_max_size:"Max file size is 6MB",
      reg_success:"Visitor Registered successfully",
      reg_failed:"Regsitration failed, please try again",
      check_all_field:"please check all the fields",
      upload_image_success:"Uplaod image Successfully",
      upload_image_failed:"Failed to upload image",
      allowed_image_extesion:"Extesions allowed are: .png, .jpg, .jpeg",
      allowed_file_extesion:"Extesions allowed are: .png, .jpg, .jpeg, .pdf",
      upload_file_success:"Uplaod image Successfully",
      upload_file_failed:"Failed to upload image",
      max_date:"The date should be before",
      mix_date:"The date should be after",
      submit_print:"Submit & Print",
      Download_Badge:"Download Badge",
      Print_Badge:"Print Badge",
      no_badge_for_type:"Sorry there is no badge for this type of visitor",
      wrong_link:"Sorry this link is incorrect",
      invalid_id:"Please enter correct ID Number",
      iqama_correct_number:"Please enter correct Iqama Number",
      passport_correct_number:"Please enter passport number should be between 7 and 10 character",
      enter_only_number:"Please enter number only",
      Failed_to_submit_form:"Failed to submit form",
      Failed_to_get_data:"Failed to get data, please try again",
      nationality:"Nationality",
      country_of_residence:"Country of Residence",
      id_number:"Id Number",
      iqama_number:"Iqama Number",
      passport_number:"Passport  Number",
      submit_print:"Submit Print",
      field_is_duplicated:"Field is Duplicated",
      Register:"Register",

      

    },
    'ar': {
      worng_link:"عذرا هذه الرابط خطأ",
      field_is_required:"هذا الحقل إجباري",
      field_is_duplicated:"مكرر",
      max_char:"الحد الأقصى للأحرف المسموح به هو ",
      min_char:"الحد الأدنى للأحرف الذي يجب إدخال هو ",
      should_greater_than:"يجب أن يكون أكثر من ",
      should_less_than:"يجب أن يكون أقل من ",
      valid_email:"الرجاء التأكد من البريد الإلكتروني",
      submit:"إرسال",
      file_max_size:"حجم الملف الأقصى للملف ٦ميجابايت",
      reg_success:"تم التسجيل بنجاح",
      reg_failed:"لقد فشل التسجل، الرجاء إعادة المحاولة",
      check_all_field:"الرجاء التأكد من جميع الخانات",
      upload_image_success:"تم تحميل الصورة بنجاح",
      upload_image_failed:"فشل تحميل الصورة",
      allowed_image_extesion:"صيغ الملفات المسموحة .png, .jpg, .jpeg",
      upload_file_success:"تم تحميل الملف بنجاح",
      upload_file_failed:"فشل تحميل الملف",
      allowed_file_extesion:"صيغ الملفات المسموحة .png, .jpg, .jpeg, .pdf",
      max_date:"يجب ان يكون التاريخ قبل",
      mix_date:"The date should be after",
      submit_print:"إرسال وطباعة",
      Download_Badge:"تحميل البطاقة الرقمية",
      Print_Badge:"طباعة البطاقة الرقمية",
      no_badge_for_type:"عذراً لا يوجد بطاقة رقمية لهذا النوع من الزوار",
      wrong_link:"عذراً, هنالك خطأ في هذا الرابط",
      invalid_id:"الرجاء التأكد من رقم الهوية",
      iqama_correct_number:"الرجاء التأكد من رقم الإقامة",
      passport_correct_number:"رقم جواز السفر يجب أن يكون بين سبعة إلى عشرة أرقام وأحرف",
      enter_only_number:"الرجاء إدخال أرقام فقط",
      Failed_to_submit_form:"فشل إرسال النموذج",
      Failed_to_get_data:"فشل الحصول على البيانات، يرجى المحاولة مرة أخرى",
      nationality:"الجنسية",
      country_of_residence:"بلد الإقامة",
      id_number:"رقم الهوية",
      iqama_number:"رقم الإقامة",
      passport_number:"رقم جواز السفر",
      submit_print:"إرسال وطباعة",
      field_is_duplicated:"إيجابة هذا الحقل مسجلة مسبقاً",
      Register:"تسجيل",

      

      

    },
  },
})
export default i18n
