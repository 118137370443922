import { createRouter, createWebHistory } from "vue-router";


import store from '../store/index';

const routes = [
    {
        path: "/",
        component: () => import("../views/NoLayout.vue"),
        redirect: "/admin/login",
        
    },

    {
        path: "/badge",
        name: "badge",
        component: () => import("../views/badge/badge.vue"),
    },
    {
        path: "/search",
        name: "search",
        component: () => import("../views/Search.vue"),
    },
    {
        path: "/search2",
        name: "search2",
        component: () => import("../views/Search2.vue"),
    },
    {
        path: "/thankyou",
        name: "thankyou",
        component: () => import("../views/thankyou.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../views/Register.vue"),
    },
    {
        path: "/admin",
        component: () => import("../views/NoLayout.vue"),
        children: [
            {
                path: "login",
                name: "login",
                meta: { requiresNoAuth: true },
                component: () => import("../views/Login.vue"),
            },
            {
                path: "createbadge",
                name: "createbadge",
                component: () => import("../views/badge/CreateBadge.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "create-email-template",
                name: "createEmailTemplate",
                component: () => import("../views/Email/CreateEmailTemplate.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "edit-email-template",
                name: "editEmailTemplate",
                component: () => import("../views/Email/EditEmailTemplate.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "updatebadge",
                name: "updatebadge",
                component: () => import("../views/UpdateBadge.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "displaybadge",
                name: "displaybadge",
                component: () => import("../views/badge/DisplayBadge.vue"),
                meta: { requiresAuth: true },
            },
        ],
    },
    {
        path: "/admin",
        component: () => import("../views/Layout.vue"),
        children: [
            {
                path: "view-email-template",
                name: "ViewEmailTemplate",
                component: () => import("../views/Email/ViewEmailTemplate.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "users",
                name: "users",
                component: () => import("../views/Users.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "home",
                name: "home",
                component: () => import("../views/Home.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "role-permission",
                name: "RolePermission",
                component: () => import("../views/RolePermission.vue"),
                meta: { requiresAuth: true },
            },
            

            {
                path: "fields",
                name: "fields",
                component: () => import("../views/fields/FieldsNew.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "add-field",
                name: "addField",
                component: () => import("../views/fields/AddField.vue"),
                meta: { requiresAuth: true },
            },
            
            
            {
                path: "edit-field",
                name: "editField",
                component: () => import("../views/fields/EditField.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "dashboard",
                name: "dashboard",
                component: () => import("../views/Dashboard.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "type",
                name: "type",
                component: () => import("../views/Type.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "font",
                name: "font",
                component: () => import("../views/Font.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "admin",
                name: "admin",
                component: () => import("../views/Admin.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "webpage",
                name: "webpage",
                component: () => import("../views/WebPage.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "email-config",
                name: "emailConfig",
                component: () => import("../views/EmailConfig.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "email-template",
                name: "emailTemplate",
                component: () => import("../views/Email/EmailTemplate.vue"),
                meta: { requiresAuth: true },
            },
            
            
            {
                path: "userinfo",
                name: "userinfo",
                component: () => import("../views/UserInfo.vue"),
                meta: { requiresAuth: true },
            },
            {
                path: "badges",
                name: "badges",
                component: () => import("../views/badge/badges.vue"),
                meta: { requiresAuth: true },
            },
            
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    
    // const timeDifference = otherTime.getTime() - currentTime.getTime();
    const _RSRoles= JSON.parse(localStorage.getItem('_RSRoles'));
    const userAuthenticated = ($cookies.get("_RSUser") !== null && _RSRoles!==null);
    if(_RSRoles!==null){
        const storedTime = new Date(_RSRoles.time);
        const currentTime = new Date();
        const timeDifference = currentTime.getTime() - storedTime.getTime();
        const hoursDifference = timeDifference / (1000 * 3600)
        if(hoursDifference>10){
            localStorage.removeItem('_RSRoles');
            store.state.roles=[];
        }
        else{
            if(_RSRoles.data.length>0){
                store.state.roles=_RSRoles.data[0].permissions; 
                store.state.role_id=_RSRoles.data[0].id; 
            }
            else{
                store.state.roles=[];
                store.state.role_id=null;
            }
            
        }
    }
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth); // dashboard route
    const requiresNoAuth = to.matched.some(
        (record) => record.meta.requiresNoAuth
    ); //login route
    if (requiresAuth && !userAuthenticated) {
        next("/admin/login");
    } else if (requiresNoAuth && userAuthenticated) {
        next("/admin/home");
    } else {
        next();
    }
});

// router.beforeEach((to, from, next) => {
//     const hasToken = token !== null;
//     if (to.meta.requiresAuth == true) {
//         if (hasToken) {
//             next();
//         } else {
//             next("/admin/login");
//         }
//     } else {
//         next();
//     }
// });

export default router;
