import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "./i18n";
import VueCookies from "vue-cookies";
import router from './router';
import "./assets/bea.css";
import "./assets/custom.css";
import "./assets/mdi.css";
import "./assets/MyStyle.css";

import "./utils/api";
import Select2 from "vue3-select2-component";
import VueHtml2Canvas from 'vue-html2canvas';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

library.add(faInstagram ,faYoutube ,faFacebookF ,faTwitter ,faLinkedinIn ,faPen ,faTrash ,faPlus ,faXmark ,faSortDown, faEye);

const app = createApp(App).use(store).use(router);
app.use(i18n);
app.use(VueAxios, axios);
app.use(VueHtml2Canvas);

app.component("Select2", Select2);

// app.use(VueCookies, {
//   expires: "1d",
// })
app.use(VueCookies)

app.use(VueToast);

app.component("font-awesome-icon", FontAwesomeIcon)

app.mount("#app");
