<script setup>

</script>

<template>
  <div style="min-height:100vh; background-color:#252962; ">
  <router-view/>
</div>
</template>

<style scoped>

</style>